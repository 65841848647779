<template>
  <div class="table__row" :class="{'table__row--dec': !company.active}">
    <div class="table__cell">
      <toggle-button color="#0290FC" :width="40" :sync="true" :value="company.active" @change="modal" />
    </div>
    <div class="table__cell">
      <div class="table__cell-title">{{ company.name }}</div>
      <div class="table__cell-subtitle">{{ company.index }} {{ company.name }}</div>
    </div>
    <div class="table__cell">
      <div class="table__cell-title">{{ company.country }}</div>
      <div class="table__cell-subtitle">{{ company.province }} {{ company.city }}</div>
    </div>
    <div class="table__cell">
      <div class="table__cell-title">{{ company.adminName }}</div>
      <div class="table__cell-subtitle">{{ company.adminEmail }}</div>
    </div>
    <div class="table__cell">
      <div class="table__cell-title">{{ company.phone }}</div>
    </div>
    <div class="table__cell">
      <div class="table__cell-title">{{ company.HSTnumber }}</div>
    </div>
    <div class="table__cell">
      <div class="table__cell-title">{{ company.website }}</div>
    </div>
  </div>
</template>

<script>
import {ToggleButton} from 'vue-js-toggle-button'
// import modal from '@/components/ModalToggle'

export default {
  name: 'sAdminCompany',
  components: {
    ToggleButton
  },
  props: {
    company: {
      type: Object,
      default: null
    },
    toggleModal: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    active: Boolean
  }),
  created() {
    this.active = this.company.active
  },
  methods: {
    modal() {
      // const style = this.$modal.styles
      // const active = this.active
      // const text = 'Rodrix Fasteners'
      // this.active = !this.active
      // this.$modal.show(modal, {active, text}, style, {
      //   'before-open': this.$modal.open,
      //   'before-close': this.$modal.close
      // })
      // this.$parent.$options.methods.toggleModal()
      this.toggleModal(this.company)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
