<template>
  <div class="page-wrapper companies__wrapper">
    <div class="container">
      <div class="companies__inner page-inner">
        <div class="companies__title-wrapper">
          <div class="companies__title">SaaS Companies</div>
          <div class="companies__button button button--fill">Add New Comapny</div>
        </div>
        <div class="companies__table table">
          <div class="table__title">
            <div class="table__title-item">On/Off</div>
            <div class="table__title-item">Company Name</div>
            <div class="table__title-item">Location</div>
            <div class="table__title-item">Admin</div>
            <div class="table__title-item">Phone number</div>
            <div class="table__title-item">HST number</div>
            <div class="table__title-item">Website</div>
          </div>
          <div class="table__main">
            <Company
              v-for="company in companies"
              :key="company.id"
              :company="company"
              :toggle-modal="toggleModal"
            />
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="showModal"
        class="modal__wrapper"
        :class="{'show-modal': showModal}"
        @click.self="toggleModal"
      >
        <div class="modal__inner">
          <div class="modal__title">
            Are you sure you want to
            <span v-if="activeCompany.active"> deactivate</span>
            <span v-else> activate </span>
            <span>{{ activeCompany.name }}?</span>
          </div>
          <div class="modal__buttons">
            <button class="button button--fill" @click="changeActive">
              <span v-if="activeCompany.active"> Deactivate</span>
              <span v-else> Activate </span>
            </button>
            <button class="button" @click="toggleModal">Cancel</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Company from '@/views/SuperAdmin/Company'
export default {
  name: 'sAdminCompanies',
  components: {Company},
  data: () => ({
    companies: [
      {
        id: 1,
        active: false,
        name: 'Rodrix Fasteners',
        address: 'Pilgrim Drive Suite 97',
        index: '9160',
        country: '🇨🇦 Canada',
        province: 'ON.',
        city: 'Toronto',
        adminName: 'Johny Cash',
        adminEmail: 'johnycash@mail.me',
        phone: '+23 232 23 23',
        HSTnumber: '568425625',
        website: 'asdas.com'
      },
      {
        id: 2,
        active: true,
        name: 'Rodrix Fasteners',
        address: 'Pilgrim Drive Suite 97',
        index: '9160',
        country: '🇨🇦 Canada',
        province: 'ON.',
        city: 'Toronto',
        adminName: 'Johny Cash',
        adminEmail: 'johnycash@mail.me',
        phone: '+23 232 23 23',
        HSTnumber: '568425625',
        website: 'asdas.com'
      }
    ],
    activeCompany: Object,
    showModal: false
  }),
  methods: {
    toggleModal(company) {
      this.showModal = !this.showModal
      this.activeCompany = company
    },
    changeActive() {
      this.activeCompany.active = !this.activeCompany.active
      this.showModal = !this.showModal
      this.activeCompany = Function
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
